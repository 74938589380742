<template>
<div id="app">
    <v-app id="inspire">
        <v-row justify="center">
            <v-dialog 
                v-model="value.open" 
                width="450px"
                light=""
                >
                <v-card>
                    <v-card-title class="headline">{{ value.title }}</v-card-title>
                    <v-card-text>{{ value.message }}</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <div 
                            class="text-tag-btn text-tag-red d-flex align-center justify-center mr-2" 
                            text 
                            style="width:120px; cursor:pointer"
                            @click="value.open = false"
                            >Disagree</div>
                        <div 
                            class="text-tag-btn text-tag-green d-flex align-center justify-center mr-2" 
                            text 
                            style="width:120px; cursor:pointer"
                            @click="(value.open = false, value.confirm = true)"
                            >Agree</div>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-app>
</div>
</template>


<script>
export default {
    name: 'Confirm',
    props: {
        value:Object
    }
}
</script>

<style scoped lang="scss">

</style>